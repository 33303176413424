@import '/src/variables.scss';

.header {
  padding: 0 15px 20px 15px;
  color: $darkGrey;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.table {
  width: 100%;
  max-width: 100%;
  display: block;
  overflow-y: hidden;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;

  .tableBody {
    th {
      text-align: center;
      align-items: center;
      padding: 15px;
      vertical-align: middle;
    }

    td:nth-child(1),
    td:nth-child(2),
    th:nth-child(1),
    th:nth-child(2) {
      width: 25%; /* Половина ширины таблицы для первых двух столбцов */
    }

    td {
      text-align: center;
      align-items: center;
      padding: 15px;
      vertical-align: middle;
      background-color: $whiteColor;
    }

    .status {
      text-align: left;
      padding-left: 15px;
    }

    .lastItem {
      padding-right: 15px;
    }
  }

  .tableHeader {
    border-top: 1px solid $strokeGreyColor;
    border-bottom: 1px solid $strokeGreyColor;
    padding: 10px 0px;
    background-color: $strokeGreyColor;

    color: $greyTextColor;
    text-align: center;
    font-family: Loko_Lato-Black;
    font-size: 14px;
    font-style: normal;
    line-height: normal;

    .deletions {
      text-align: left;
      width: 50%;
    }
  }

  .tableRow {
    border-bottom: 1px solid $strokeGreyColor;

    color: $blackColor;
    font-family: Loko_Lato-regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: $whiteColor;

    .time {
      display: flex;
      gap: 4px;

      .playIcon {
        width: 14px;
        height: 14px;
      }
    }

    .image {
      width: 32px;
      height: 32px;
    }

    .nameAndReason {
      width: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;

      .name {
        color: $blackColor;
        font-family: Loko_Lato-Bold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
      }

      .reason {
        color: $darkGrey;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .total {
    color: $blackColor;
    font-family: Loko_Lato-Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.duration {
  font-family: Loko_Lato-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: center;
  color: $darkGrey;
}
