@import '/src/variables.scss';

.tabBar {
  padding: 0px 20px;
  white-space: nowrap;
  overflow-x: auto;
}

.tabBar p {
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  color: $notActiveTab;
  line-height: 1.2;
  margin-right: 60px;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
  margin-bottom: 30px;
  cursor: pointer;
}

.tabBar p:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $mainRedColor;
  opacity: 0;
  transition: opacity 0.2s;
}

.tabBar p.active,
.tabBar p:active,
.tabBar p:focus,
.tabBar p:hover {
  color: $mainRedColor !important;
  font-family: Loko_Lato-Bold;
  font-weight: 800;
  text-decoration: none;
}

.tabBar p.active:after,
.tabBar p:active:after,
.tabBar p:focus:after,
.tabBar p:hover:after {
  opacity: 1;
  color: $mainRedColor !important;
}

.buttonWrapper {
  margin-top: 40px;
}

@media screen and (max-width: 1500px) {
  .tabBar p {
    font-size: 14px;
  }
}

.article {
  padding: 0px 20px 30px 20px;
  display: block;
  overflow-x: auto;

  .title {
    margin-bottom: 20px;
    color: $blackColor;
    font-family: Loko_Lato-Bold;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0em;
    text-align: left;
  }
}

.mediaTabs {
  display: flex;
  gap: 7.74px;
}

.mediaTab {
  width: 180px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none !important;
  padding: 6px 19px;
  background-color: $strokeGreyColor;
  font-family: Loko_Lato-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: $notActiveTab;
  cursor: pointer;

  &Active {
    background-color: $mainRedColor;
    color: $whiteColor;
  }
}

.buttonWrapper {
  margin-top: 30px;
}

.photosGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 1100px) {
  .photosGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 760px) {
  .photosGrid {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
