@import '/src/variables.scss';

.header {
  height: 40vh;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: end;

  .text {
    margin-bottom: 50px;
    color: $whiteColor;
    font-family: L_Halvar_Mittelschrift-Regular;
    font-size: 74px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    z-index: 1000;
  }
}

.wrapper {
  display: flex;
  padding: 30px 20px;
  border-radius: 10px 10px 0 0;
  gap: 20px;
  background-color: $whiteColor;

  .content {
    flex-basis: calc((100% - 20px) * 2 / 3);

    .videoList {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .video {
        flex-basis: calc((100% - 2 * 20px) / 3);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border-radius: 10px 10px 0 0;
        border: 1px solid $strokeGreyColor;
        overflow: hidden;

        .previewImage {
          width: 100%;
          aspect-ratio: 16/9;
          object-fit: cover;
        }
      }
    }
  }
}

.description,
.descriptionMainVideo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  height: 100px;
  box-sizing: content-box;

  .title {
    font-family: Loko_Lato-Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $blackColor;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    font-family: Loko_Lato-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $greyTextColor;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.descriptionMainVideo {
  margin-bottom: 15px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid $strokeGreyColor;
}

.sideMenu {
  flex-basis: calc((100% - 20px) / 3);
  max-width: calc((100% - 20px) / 3);
}

.buttonWrapper {
  padding: 30px 0;

  .youtubeLinkButton {
    width: 100%;
    padding: 16px 20px;
    background-color: $strokeGreyColor;
    outline: none !important;
    border: none;
    border-radius: 8px;
    justify-content: center;
    display: flex;
    gap: 10px;
    align-items: center;

    .text {
      font-family: Loko_Lato-Regular;
      font-weight: 500;
      font-size: 16px;
      color: $mainRedColor;
      text-transform: uppercase;
    }
  }
}

.ScrollToTopButtonWrapper {
  margin-top: 30px;
}

.previewImageMainVideo {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

@media screen and (max-width: 1320px) {
  .contentWrapper {
    padding-left: 16px;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    flex-direction: column;

    .content,
    .sideMenu {
      max-width: 100%;
    }

    .content {
      .videoList {
        .video {
          flex-basis: calc((100% - 20px) / 2);
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .wrapper {
    .content {
      .videoList {
        .video {
          flex-basis: 100%;
        }
      }
    }
  }
}
