@import '/src/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  .articleDate {
    font-family: Loko_Lato-Bold;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    color: $blackColor;
  }
}

.newsBlock {
  :global {
    iframe {
      width: 100%;
      height: 56vw;
      max-height: 480px;
    }
  }

  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background-color: $whiteColor;

  .newsBlockTitle {
    font-family: L_Halvar_Mittelschrift-Bold;
    color: $blackColor;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .newsBlockContent {
    border-bottom: 1px solid $strokeGreyColor;
    font-family: Loko_Lato-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.9;
    letter-spacing: 0em;
    text-align: left;
    color: $blackColor;
  }

  .newsBlockContentFooter {
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .author {
      font-family: Loko_Lato-Regular;
      margin: 0;
      color: $greyTextColor;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
    }
  }
  .shareContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid $strokeGreyColor;

    .social {
      color: $greyTextColor;
      font-family: Loko_Lato-Regular;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
    }

    .copyLink {
      display: flex;
      align-items: center;
      color: $greyTextColor;
      cursor: pointer;
      p {
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        margin: 0 0 0 8px;
      }
    }
  }
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .tags {
      display: flex;
      align-items: center;
      color: $greyTextColor;
      font-family: Loko_Lato-Regular;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
    }
  }

  .highlights {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 30px;
    &Title {
      font-family: L_Halvar_Mittelschrift-Bold;
      font-size: 22px;
      font-style: normal;
      line-height: normal;
      color: black;
    }

    .highlightsSlider {
      :global {
        .slick-list {
          position: relative;
          width: 100%;
        }

        .slick-track {
          overflow: hidden;
        }

        .slick-arrow {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);
          filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
        }

        .slick-next {
          right: 0;
        }

        .slick-slide {
          width: 200px !important;
          margin-right: 5px;
        }
      }
    }
  }

  .highlightsSlider {
    :global {
      .slick-list {
        position: relative;
        width: 100%;
      }
      .slick-arrow {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
      }

      .slick-next {
        right: 0;
      }

      .slick-slide {
        width: 200px !important;
        margin-right: 5px;
      }

      .slick-disabled {
        display: none;
      }
    }
  }
}
