@import '/src/variables.scss';

.header {
  padding: 0 15px 20px 15px;
  color: $darkGrey;
  font-family: L_Halvar_Mittelschrift-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid $strokeGreyColor;

  .playerInfo {
    display: flex;
    gap: 10px;
    align-items: center;
    .playerNumber {
      color: $blackColor;
      font-family: hc_Lokomotiv;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .imageWrapper {
      border: 5px solid $darkBorderColor;

      .image {
        min-width: 50px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }
    }

    .scorer {
      display: flex;
      flex-direction: column;
      gap: 3px;

      .main {
        color: $blackColor;
        font-family: Loko_Lato-Bold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .assist {
        color: $darkGreyText;
        font-family: Loko_Lato-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.56px;
      }
    }
  }

  .scoreWrapper {
    display: flex;
    border-radius: 3px;
    border: 0.5px solid $mainRedColor;
    min-width: 120px;

    .time {
      padding: 4px 6px;
      color: $darkGreyText;
      font-family: Loko_Lato-Regular;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .score {
      padding: 4px 0;
      color: $whiteColor;
      font-family: Loko_Lato-Bold;
      font-size: 8px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background-color: $mainRedColor;
      flex-grow: 1;
      text-align: center;
    }

    .pmg {
      padding: 4px 2px;
      font-family: Loko_Lato-Regular;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $darkRedColor;
    }
  }
}

.withoutGoals {
  padding: 20px 15px;
  color: $blackColor;
  font-family: Loko_Lato-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.period {
  padding: 10px 15px;
  color: $greyTextColor;
  font-family: Loko_Lato-Black;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  background-color: $articleContentBackground;
}

.spinner {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .row {
    .playerInfo {
      .scorer {
        .main {
          font-size: 12px;
        }
      }
    }
    .scoreWrapper {
      width: 100px;
    }
  }
}
